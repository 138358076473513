/* -The main kanji table
-----------------------------------------------------------------------------*/

/* -------------------- Intro -------------------- */
.main-table-intro {
  @include bordered;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 1em;
}
.main-table-intro-col {
  & + & {
    padding-left: 10px;
  }
  &:first-child {
    margin-right: auto;
  }
}

/* -------------------- Table -------------------- */
.main-table-wrap {
  min-height: 25em;
}

.main-table {
  width: auto;

  &[data-cols='11'] {
    width: 100%;
  }
  th:first-child {
    padding-left: 15px;
    padding-right: 25px;

    a {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  .no-results {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}
