/* -Base form elements
-----------------------------------------------------------------------------*/
input[type='number'],
input[type='search'],
input[type='text'],
textarea {
  @include theme-rule(
    $rule: border,
    $color-name: 'foreground',
    $value-template: '1px solid {{color}}',
    $tint-shade-amount: 60%,
    $tint-shade-reverse: true
  );
  @include theme-rule(
    $rule: background-color,
    $color-name: 'background',
    $tint-shade-amount: 5%,
    $tint-shade-reverse: true
  );

  height: rem($form-control-height);
  padding: 0 rem($form-control-padding);
  border-radius: 3px;
  font-size: 1rem;
  transition: border-color 0.15s linear;

  &:focus {
    @include theme-rule(border-color, 'action');
    outline: 0;
  }
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: middle;
  margin-right: 0.25em;
}

textarea {
  height: auto;
  padding: rem($form-control-padding);
}

select {
  height: rem($form-control-height);
  padding: 0 rem($form-control-padding);
}

input[type='number'],
input[type='search'],
input[type='text'],
textarea,
select {
  .show-focus &:focus {
    outline: 2px dotted;
  }
  &[disabled] {
    opacity: 0.5;
  }
}
