/* -Layout
-----------------------------------------------------------------------------*/
$page-padding: 20px;
$page-width: 800px;

$icon-size: 16px;

$table-cell-padding-horizontal: 7px;
$table-cell-edge-padding-horizontal: 10px;
$table-cell-padding-vertical: 3px;

$form-control-height: 32px;
$form-control-padding: 5px;

$media-query-smaller-type: 550px;

/* -Type
-----------------------------------------------------------------------------*/
$font-stack-main: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$font-stack-mono: Consolas, SFMono-Regular, monospace;
$font-stack-jp: 'Source Han Serif', source-han-serif-japanese, sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-jp-regular: 400;
$font-weight-jp-bold: 900;

$base-font-size: 16px;
$base-line-height: 1.5;
$base-type-spacing: $base-line-height * 1em;

$h1: 36px;
$h2: 24px;
$h3: 18px;
$h4: $base-font-size;
$h5: $base-font-size;
$h6: $base-font-size;
