/* -SVG icon sprite
-----------------------------------------------------------------------------*/
.icon {
  display: inline-block;
  vertical-align: middle;
  width: $icon-size;
  height: $icon-size;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    fill: currentColor;
  }
}

// For alignment
.ghost-icon {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: $icon-size;
}
