/* -Global app layout
-----------------------------------------------------------------------------*/
.root {
  padding: $page-padding;
}

.app {
  max-width: rem($page-width);
  margin: 0 auto;
}
