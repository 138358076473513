/* -Generic toggle dialog
-----------------------------------------------------------------------------*/
.toggle-dialog-body {
  display: none;
  z-index: 2;

  .toggle-dialog--open & {
    display: block;
  }
}
