/* -Base typography
-----------------------------------------------------------------------------*/
body {
  line-height: $base-line-height;
}

// Headings
// Should generally prefer @include to @extend, but headings can have media
// queries that should apply to anything that wants to mimic a heading.
%heading {
  font-weight: $font-weight-bold;
}
%h1 {
  @extend %heading;
  font-size: rem($h1);
}
%h2 {
  @extend %heading;
  font-size: rem($h2);
}
%h3 {
  @extend %heading;
  font-size: rem($h3);
}
%h4 {
  @extend %heading;
  font-size: rem($h4);
}
%h5 {
  @extend %heading;
  font-size: rem($h5);
}
%h6 {
  @extend %heading;
  font-size: rem($h6);
}
@include media-max(rem($media-query-smaller-type)) {
  %h1 {
    font-size: rem($h1 - 16px);
  }
  %h2 {
    font-size: rem($h2 - 6px);
  }
  %h3 {
    font-size: rem($h3 - 3px);
  }
}

h1,
.h1 {
  @extend %h1;
}
h2,
.h2 {
  @extend %h2;
}
h3,
.h3 {
  @extend %h3;
}
h4,
.h4 {
  @extend %h4;
}
h5,
.h5 {
  @extend %h5;
}
h6,
.h6 {
  @extend %h6;
}

// Content element spacing
p,
ol,
ul,
table {
  margin-bottom: $base-type-spacing;
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6,
h1 + blockquote,
h2 + blockquote,
h3 + blockquote,
h4 + blockquote,
h5 + blockquote,
h6 + blockquote {
  margin-top: 5px;
}

// Lists
ul,
ol {
  margin-left: 1em;

  ul,
  ol {
    margin-bottom: 0;
  }
}

// Blockquote
blockquote {
  position: relative;
  padding-left: 35px;

  &::before {
    content: '\201C';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 50px;
    line-height: 50px;
    font-weight: bold;
  }
  + p cite {
    display: block;
    margin-top: -$base-type-spacing / 2;
  }
}

// Inline quote
q {
  font-style: italic;
}

// Inline element to use alongside icons and such when it should align
.text {
  vertical-align: middle;
}
