/* -BetweenInput component
-----------------------------------------------------------------------------*/
.between-input {
  input {
    width: 3.5em;
  }
}

.between-input-label {
  display: block;
  margin-bottom: 3px;
  font-weight: $font-weight-bold;
}

.between-input-label-text {
  margin-left: 0.25em;
  margin-right: 0.25em;

  label:first-of-type & {
    margin-left: 0;
  }
}
