/* -View control dialog
-----------------------------------------------------------------------------*/
.view-control {
  position: relative;

  ul {
    list-style: none;
    margin: 0;
  }
  ul ul {
    margin-left: 1.25em;
  }
  .toggle-dialog-trigger {
    position: relative;
    vertical-align: top;
    z-index: $z-view-control + 1;
  }
  &.toggle-dialog--open .toggle-dialog-trigger {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  label {
    white-space: nowrap;
  }
}

.view-control-body {
  @include theme-background;
  @include theme-rule(
    $rule: border,
    $color-name: 'action-secondary',
    $value-template: '1px solid {{color}}'
  );

  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -1px;
  min-width: 100%;
  z-index: $z-view-control;

  button:focus ~ & {
    @include theme-rule(border-color, 'action-secondary', 10%);
  }
  > div {
    padding: 10px 15px;
  }
  > div + div {
    @include theme-rule(
      $rule: border-top,
      $color-name: 'action-secondary',
      $value-template: '1px solid {{color}}'
    );
  }
}
