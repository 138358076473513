/* -Reset
-----------------------------------------------------------------------------*/
// Combination of a classic reset and normalize.css. Not using normalize
// as-is since we mostly don't want default browser styles.
//
// A bunch of unused stuff is commented out to reduce the CSS size, but kept in
// case it's needed in the future.

// Remove default spacing, essentially making everything a semantic div.
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
img,
figure,
dl,
dt,
dd,
ol,
ul,
li,
table,
caption,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

// 1. Force scrollbar.
// 2. Disable overlay color when tapping on iOS.
// 3. Prevent adjustments of font size after orientation changes on mobiles.
// 4. Normalize in all browsers.
html {
  overflow-y: scroll; // 1
  -ms-overflow-style: scrollbar; // 1
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 2
  -webkit-text-size-adjust: 100%; // 3
  -ms-text-size-adjust: 100%; // 3
  text-size-adjust: 100%; // 3
  line-height: 1; // 4
}

// border-box all the things
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Render the `main` element consistently in IE.
main {
  display: block;
}

// 1. Remove the gray background on active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
a {
  background-color: transparent; // 1
  -webkit-text-decoration-skip: objects; // 2
}

// 1. Remove the bottom border in Chrome 57- and Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// abbr[title] {
//   border-bottom: none; // 1
//   text-decoration: underline; // 2
//   text-decoration: underline dotted; // 2
// }

// Normalized weight in all browsers.
b,
strong {
  font-weight: $font-weight-bold;
}

// 1. Correct the odd em font sizing in all browsers.
// pre,
// code {
//   font-family: Consolas, monospace;
//   font-size: 1em; // 1
// }

// Prevent sub and sup elements from affecting the line height in all browsers.
// sub,
// sup {
//   font-size: 75%;
//   line-height: 0;
//   position: relative;
//   vertical-align: baseline;
//   font-weight: normal;
// }
// sub {
//   bottom: -0.25em;
// }
// sup {
//   top: -0.5em;
// }

// Limit embedded media to container size.
img/* ,
object,
embed,
iframe,
video */ {
  max-width: 100%;
  width: auto;
  height: auto;
}
img {
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;

  // Style the alt text
  font-style: italic;
  color: #666;
}

// Hide the overflow in IE.
svg:not(:root) {
  overflow: hidden;
}

// Correct display in IE10-
[hidden] {
  display: none !important;
}

// 1. Change the font styles in all browsers.
// 2. Remove the margin in Firefox and Safari.
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; // 1
  font-size: 100%; // 1
  line-height: 1.15; // 1
  margin: 0; // 2
}

// Show the overflow in IE.
// 1. Show the overflow in Edge.
button,
input {
  overflow: visible; // 1
}

// Remove the inheritance of text transform in Edge, Firefox, and IE.
// 1. Remove the inheritance of text transform in Firefox.
button,
select {
  text-transform: none; // 1
}

// Disable vendor specific form control styling
button,
input[type='search'],
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from fieldset elements in IE.
legend {
  display: table; // 1
  max-width: 100%; // 1
  white-space: normal; // 1
  color: inherit; // 2
}

// Remove the default vertical scrollbar in IE.
textarea {
  overflow: auto;
}

// Remove padding in IE
input[type='checkbox'],
input[type='radio'] {
  padding: 0;
  cursor: pointer;
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

// 1. Correct the odd appearance in Chrome and Safari.
// 2. Correct the outline style in Safari.
// input[type='search'] {
//   -webkit-appearance: textfield; // 1
//   outline-offset: -2px; // 2
// }

// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
// [type='search']::-webkit-search-cancel-button,
// [type='search']::-webkit-search-decoration {
//   -webkit-appearance: none;
// }

// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Inherit font properties in Safari.
// ::-webkit-file-upload-button {
//   -webkit-appearance: button; // 1
//   font: inherit; // 2
// }
