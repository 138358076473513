/* -Link to source on GitHub
-----------------------------------------------------------------------------*/
$github-link-icon-size: 18px;

.github-link {
  @include theme-tint-shade-transparent(
    $rule: color,
    $amount: 0.25,
    $important: true
  );

  position: absolute;
  top: 0;
  right: 0;
  width: $github-link-icon-size + 20px;
  height: $github-link-icon-size + 20px;
  padding: 10px;

  &:hover,
  &:focus {
    @include theme-tint-shade-transparent(
      $rule: color,
      $amount: 0.75,
      $important: true
    );
  }
  .icon {
    width: $github-link-icon-size;
    height: $github-link-icon-size;
    vertical-align: top;
  }
}
