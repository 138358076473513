/* -Global base styling
-----------------------------------------------------------------------------*/
html,
body {
  min-height: 100vh;
}
body {
  @include theme-background-accent;

  // Theme switching transition
  transition: background-color 0.2s ease;

  position: relative;
}
body,
button,
input,
textarea {
  @include theme-foreground;

  font-family: $font-stack-main;
  tab-size: 2;
}

// pre,
// code {
//   font-family: $font-stack-mono;
// }

// Links
a {
  &:link,
  &:visited {
    @include theme-action-color;

    // Theme switching transition
    transition: color 0.2s ease;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// Focus set with JS for accessibility reasons, don't show outline
[tabindex='-1'] {
  outline: 0;
}

// Cursors for form elements
label,
button {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: not-allowed;
}

// Navigation
nav {
  ul {
    list-style: none;
    margin: 0;
  }
}
