/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem($form-control-height);
  line-height: rem($form-control-height - 1px);
  padding: 0 10px 1px;
  border: 0;
  border-radius: 3px;
  vertical-align: baseline;
  box-shadow: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  text-shadow: none;
  font-size: 100%;
  font-weight: $font-weight-regular;
  white-space: nowrap;

  &:focus {
    @include theme-rule(
      $rule: outline,
      $value-template: '2px dotted {{color}}',
      $color-name: 'foreground'
    );
  }
}

@mixin button-primary {
  @include button;
  @include theme-rule(background-color, 'action');
  @include theme-rule(color, 'action-foreground');

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include theme-rule(background-color, 'action', 10%);
      @include theme-rule(border-color, 'action', 10%);
    }
  }
}

@mixin button-secondary {
  @include button;
  @include theme-rule(background-color, 'action-secondary');
  @include theme-rule(color, 'foreground');

  &:not([disabled]) {
    &:hover,
    &:focus {
      @include theme-rule(background-color, 'action-secondary', 10%);
      @include theme-rule(border-color, 'action-secondary', 10%);
    }
  }
}

// ---------- Standard button classes ---------- //
.btn,
a.btn,
button {
  @include button-primary;

  &[disabled] {
    opacity: 0.4;
  }
  .icon:first-child {
    margin-right: 0.25em;
  }
  .icon:last-child {
    margin-right: 0.25em;
  }
  .icon:first-child:last-child {
    margin-left: 0;
    margin-right: 0;
  }
}

// 'Invisible' button
.btn-secondary {
  @include button-secondary;
}

// 'Invisible' button
.btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent !important;
  color: inherit !important;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

// Non-button element that takes up the same space as a button
.btn-placeholder {
  @include button;
}
