/* -Extra or less focus styles
-----------------------------------------------------------------------------*/
button,
a,
[tabindex='0'] {
  &:focus {
    outline: 2px dotted;
  }
  .hide-focus &:focus {
    outline: 0 !important;
  }
}
