/* -Filter controls
-----------------------------------------------------------------------------*/
$filter-spacing: 15px;

.filter {
  label .text {
    display: inline-block;
    margin-bottom: 0.25em;
    font-weight: $font-weight-bold;
  }
}

.filter-info {
  @include theme-tint-shade-transparent(color, 0.7);

  margin-bottom: 0;
  font-size: rem(14px);
  font-style: italic;
}

.filter-kanji {
  margin: 10px 0 $filter-spacing;

  textarea {
    display: block;
    width: 100%;
  }
}

.filter-block {
  display: inline-block;
  vertical-align: top;
  margin: 0 1.75em $filter-spacing 0;
}

.filter-jlpt,
.filter-joyo {
  select {
    display: block;
    min-width: 100%;
  }
}

.filter-stroke-count {
  input {
    width: 2.5em;
  }
}

.filter-reset {
  margin-bottom: $filter-spacing;
  vertical-align: bottom;
}
