/* -Sortable table header
-----------------------------------------------------------------------------*/
.sortable-th {
  padding: 0 !important;

  button,
  .btn-placeholder {
    width: 100%;
    padding: 5px 3px 5px $table-cell-padding-horizontal;
    font-weight: inherit;
    text-align: left;
  }
  &:last-child {
    button,
    .btn-placeholder {
      padding-right: $table-cell-edge-padding-horizontal;
    }
  }
  button {
    &:hover,
    &:focus {
      @include theme-tint-shade-transparent(
        $rule: background-color,
        $amount: 0.05,
        $important: true
      );
    }
  }
  .icon {
    margin-left: 0.25em;
    opacity: 0;
  }
  .js-loaded & .icon {
    opacity: 1;
  }
  .js-loaded & .icon--sort {
    opacity: 0.5;
  }
}
